<template>
  <div>


    <div class="d-flex justify-center">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center ">
        <img style="width:300px;" :src="getFullPath(couponDetail.campaign.dataImage)">
      </div>
    </div>

    <div class="d-flex justify-center">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center pt-8">
        <div v-if="couponDetail.campaign.displayCustomerInfo === 'display'" class="mitr-font"
          style="font-size: 1.5rem; color:#2372CE">{{ couponDetail.coupon.customerName }}</div>

        <div v-else class="mitr-font" style="font-size: 1.5rem;">QR สำหรับระบบ Coupon</div>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center pt-2">
        <div class="mitr-font" style="font-size: 1rem;">เริ่มใช้ได้ {{ couponDetail.campaign.startDateTime }} จนถึง
          {{ couponDetail.campaign.endDateTime }}</div>
      </div>
    </div>

    <div v-if="couponStatus !== 'ว่างอยู่' && couponStatus !== 'ส่งออก'" class="d-flex justify-center mt-20">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center py-4">
        <!-- <img style="width:200px;" src="/media/0mine/usedCoupon.png"> -->
        <span v-if="couponStatus === 'ยกเลิก'"
          style="font-family:mitr; font-size:2rem; color:rgb(246, 78, 96) !important;">คูปองนี้ถูกยกเลิก</span>
        <span v-else-if="couponStatus === 'หมดอายุ'"
          style="font-family:mitr; font-size:2rem; color:rgb(246, 78, 96) !important;">คูปองนี้ถูกหมดอายุแล้ว</span>
        <span v-else
          style="font-family:mitr; font-size:2rem; color:rgb(246, 78, 96) !important;">คูปองนี้ถูกใช้งานแล้ว</span>

      </div>
    </div>

    <!--BEGIN: CODE-REDEEM -->
    <!-- <div v-if="(redeemType==='' || redeemType===null  || redeemType===undefined || redeemType==='code_redeem') && (couponStatus === 'ว่างอยู่' || couponStatus === 'ส่งออก')"  class="d-flex justify-center">
       <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center py-4">
          <QRCanvas :options="options"></QRCanvas>
        </div>
    </div> -->

    <div v-if="couponStatus === 'ว่างอยู่' || couponStatus === 'ส่งออก'" class="d-flex justify-center">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center py-4">
        <QRCanvas :options="options"></QRCanvas>
      </div>
    </div>

    <!-- <div v-if="(redeemType==='' || redeemType===null || redeemType===undefined  || redeemType==='code_redeem' || redeemType==='no_redeem') &&  (couponStatus === 'ว่างอยู่' || couponStatus === 'ส่งออก')"  class="d-flex justify-center "> -->
    <div class="d-flex justify-center ">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center">
        <span class="pt-2 mitr-font">{{ $route.params.couponId }}</span>
      </div>
    </div>

    <div
      v-if="(redeemType === '' || redeemType === null || redeemType === undefined || redeemType === 'code_redeem') && (couponStatus === 'ว่างอยู่' || couponStatus === 'ส่งออก')"
      class="d-flex justify-center mt-10">
      <div class="cols-6 col-sm-6 col-lg-4 col-md-4 d-flex justify-center">
        <b-form-input :type="text" placeholder="ระบุรหัสสำหรับตรวจสอบคูปอง" v-model="redeemCode"
          style="border-top-right-radius:0;  border-bottom-right-radius:0;" />

        <b-button @click="redeem()" variant="primary" class="btn btn-primary  text-uppercase px-9  "
          style="font-family: mitr; font-weight: bold; font-size: 1rem; border-top-left-radius:0;  border-bottom-left-radius:0;"
          data-wizard-type="action-submit">
          ยืนยัน
        </b-button>

      </div>
    </div>

    <!-- END: CODE-REDEEM -->

    <!-- SELF-REDEEM -->

    <div v-if="(redeemType === 'self_redeem') && (couponStatus === 'ว่างอยู่' || couponStatus === 'ส่งออก')"
      class="d-flex justify-center mt-10">
      <div class="cols-6 col-sm-6 col-lg-4 col-md-4 d-flex justify-center">

        <b-button @click="self_redeem()" variant="primary" class="btn btn-primary  text-uppercase px-9  "
          style="font-family: mitr; font: size 1.2rem; font-weight: bold; font-size: 1rem; width:100%;"
          data-wizard-type="action-submit">
          ยืนยันใช้คูปอง
        </b-button>

      </div>
    </div>

    <!-- SELF-REDEEM -->




    <!-- Terms and Condition -->
    <div class="d-flex justify-center mt-20">
      <div class="cols-12 col-sm-12 col-lg-6 col-md-12 d-flex justify-center py-4">
        <!-- <img style="width:200px;" src="/media/0mine/usedCoupon.png"> -->
        <b-form-textarea plaintext rows="15" max-rows="15" v-model="couponDetail.campaign.termAndCondition" />
        <!-- <span  style="font-family:mitr; color:rgb(246, 78, 96) !important;">
              {{couponDetail.campaign.termAndCondition}}
            </span> -->

      </div>
    </div>



  </div>
</template>

<script>
import AdminCampaignListTable from "@/view/alex/eCouponAdminCampaignListTable.vue";
import FormBrandDetail from "@/view/alex/eCouponBrandForm.vue";

import ListWidget1 from "@/view/alex/Widget1.vue";
import shapeFormat from '@/mixins/shapeFormat.js';
import axios from 'axios'
import Swal from 'sweetalert2';

const Vue = require('vue');
const { QRCanvas } = require('qrcanvas-vue');

export default {
  name: "mobile customer qr",
  mixins: [shapeFormat],
  data() {
    return {

      redeemType: "",
      couponStatus: "",
      redeemCode: "",

      options: {
        cellSize: 6,
        data: "",
      },

      couponDetail: {},

    }
  },

  async created() {
    // this.tableDetail.data = this.items;
    this.options.data = this.$route.params.couponId;
    this.couponDetail = await this.$store.dispatch("coupon/accessCoupon", this.$route.params.couponId);

    this.couponStatus = this.couponDetail.coupon.couponStatus;
    if (this.couponDetail.campaign === null || this.couponDetail.campaign === undefined) {
      this.redeemType = '';
    }

    if (this.couponDetail.coupon.couponStatus === "ยกเลิก") {
      window.location.href = "/404";
    }

    this.redeemType = this.couponDetail.campaign.redeemType;


    console.log("this.couponDetail >>> ", this.couponDetail);
    console.log("this.redeemType >>> ", this.redeemType);

  },
  computed: {
    // ...mapGetters(["items"])
  },

  components: {
    QRCanvas,
  },
  // *** [add-26May2022]
  methods: {

    async redeem() {
      let params = {
        couponCode: this.$route.params.couponId,
        redeemCode: this.redeemCode
      };

      let res = null;

      try {

        res = await this.$store.dispatch("coupon/redeem", params);
        if (res.couponStatus !== null && res.couponStatus !== undefined) {
          this.couponStatus = res.couponStatus;
        }

      }
      catch (err) {
        // this.couponDetail = "O"
      }
    },
    async self_redeem() {



      let url = "/ecoupon-api/ecoupon/self-redeem";
      let data = {
        couponCode: this.$route.params.couponId,
      };
      //  let form = param.formDetail

      // console.log("%%%%%%%%%% self_redeem >>> ",this.$store.state.common.baseUrl + url);
      //  console.log("%%%%%%%%%% data >>> ",data);
      //  console.log("%%%%%%%%%% this.$store.state.auth.apiHeader >>> ",this.$store.state.auth.apiHeader);

      let resp = null;
      let res = null;

      try {
        resp = await axios.put(this.$store.state.common.baseUrl + url, data, this.$store.state.auth.apiHeader);
        // //*** ส่ง res.data กลับมาเป็น couponId */
        // await store.this.$store.dispatch("coupon/loadData", res.data);

        Swal.fire({
          title: "",
          text: "ใช้งานคูปองแล้ว",
          icon: "success",
          confirmButtonClass: "btn btn-secondary"
        });


        res = resp.data;
        if (res.couponStatus !== null && res.couponStatus !== undefined) {
          this.couponStatus = res.couponStatus;
        }



      } catch (err) {
        Swal.fire({
          icon: 'warning',
          title: 'รหัสตรวจสอบคูปองไม่ถูกต้อง',
          text: "โปรดติดต่อพนักงานร้านค้า"
        });
      }

    },

  }
}
</script>

<style  >
.wrapper {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.content {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.topbar {
  display: none !important;
}

#kt_header {
  display: none !important;
}
</style>


